import { connect } from 'react-redux'
import { Record, String, Static, Array, Function } from 'runtypes'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { actions } from '../../../store'
import NewsApi from '../../../api/news'
import styles from './SportScheduleFeed.module.scss'
import NewsFeedItem from '../NewsFeedItem/NewsFeedItem'

const SportScheduleMatch = Record({
  time: String,
  sport: String,
  channel: String,
  competition: String,
  game: String,
})

type SportScheduleMatch = Static<typeof SportScheduleMatch>

const SportScheduleFeedProps = Record({
  sportSchedule: Array(SportScheduleMatch),
  setSportSchedule: Function,
})

type SportScheduleFeedProps = Static<typeof SportScheduleFeedProps>

const SportScheduleFeed = ({
  sportSchedule,
  setSportSchedule,
}: SportScheduleFeedProps): JSX.Element => {
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)

  useEffect(() => {
    getSportScheduleFeed()
  }, [])

  const getSportScheduleFeed = async () => {
    if (hasMore) {
      try {
        const sportScheduleResponse = await NewsApi.getSportSchedule(page)
        setPage(page + 1)
        if (sportScheduleResponse.data.data.length) {
          setSportSchedule(sportScheduleResponse.data.data)
        } else {
          setHasMore(false)
        }
      } catch (e) {
        setSportSchedule([])
      }
    }
  }

  if (!sportSchedule.length) return null

  return (
    <div className={`${styles.sportScheduleFeed} mt-5 bg-white pb-2.5`}>
      {sportSchedule.length && (
        <p className={styles.title}>
          <span className="px-10 leading-8 font-bold text-white">
            SPORTSKI EKRAN
          </span>
        </p>
      )}
      {sportSchedule && sportSchedule.length ? (
        <InfiniteScroll
          dataLength={sportSchedule.length}
          next={getSportScheduleFeed}
          hasMore={hasMore}
          loader={null}
          className="sport-schedule-scroll mt-1.5"
          height={330}
        >
          {sportSchedule.map((match: SportScheduleMatch, i: number) => {
            return (
              <NewsFeedItem
                key={i}
                time={match.time}
                subtitle={`${match.channel} / ${match.sport} / ${match.competition}`}
                title={`${match.game}`}
                isClickable={false}
                slug={null}
                category={null}
                isSportScheduleItem={true}
              />
            )
          })}
        </InfiniteScroll>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sportSchedule: state.sportSchedule,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSportSchedule: (data) => {
      dispatch({
        type: actions.SET_SPORT_SCHEDULE,
        payload: {
          sportSchedule: data,
        },
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SportScheduleFeed)
