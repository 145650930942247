import { useState } from 'react'
import LiveNewsFeed from './LiveNewsFeed'
import MostReadFeed from '../MostReadFeed/MostReadFeed'

import styles from './MobileNewsTabs.module.scss'

export const CommentIcon = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="ml-3"
    width="25"
    height="25"
    viewBox="0 0 512 512"
  >
    <path
      fill={active ? '#ffffff' : '#DD2A30'}
      d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z"
    />
  </svg>
)

export const TrendIcon = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="ml-3"
    width="25"
    height="25"
    viewBox="0 0 576 512"
  >
    <path
      fill={active ? '#ffffff' : '#DD2A30'}
      d="M384 160C366.3 160 352 145.7 352 128C352 110.3 366.3 96 384 96H544C561.7 96 576 110.3 576 128V288C576 305.7 561.7 320 544 320C526.3 320 512 305.7 512 288V205.3L342.6 374.6C330.1 387.1 309.9 387.1 297.4 374.6L191.1 269.3L54.63 406.6C42.13 419.1 21.87 419.1 9.372 406.6C-3.124 394.1-3.124 373.9 9.372 361.4L169.4 201.4C181.9 188.9 202.1 188.9 214.6 201.4L320 306.7L466.7 159.1L384 160z"
    />
  </svg>
)

const MobileNewsTabs = () => {
  const [newsTabs, setNewsTabs] = useState(true)

  return (
    <div className={`w-full p-2 bg-white ${styles.mobile_news_container}`}>
      <div className="w-full flex flex-row justify-start items-center">
        <button
          className={`bg-white px-6 py-2 font-bold ${
            newsTabs ? ' bg-red-600 text-white' : ' border-2 '
          }`}
          onClick={(e) => setNewsTabs(true)}
        >
          <span className="flex flex-row justify-between items-center">
            NAJNOVIJE <TrendIcon active={newsTabs && true} />
          </span>
        </button>
        <button
          className={`bg-white px-6 py-2 font-bold ml-2 ${
            !newsTabs ? ' bg-red-600 text-white' : ' border-2 '
          }`}
          onClick={(e) => setNewsTabs(false)}
        >
          <span className="flex flex-row justify-between items-center">
            NAJČITANIJE <CommentIcon active={!newsTabs && true} />
          </span>
        </button>
      </div>
      <div>{newsTabs ? <LiveNewsFeed /> : <MostReadFeed />}</div>
    </div>
  )
}

export default MobileNewsTabs
