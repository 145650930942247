import Link from 'next/link'
import { Null, Record, Static, Union } from 'runtypes'

import styles from './PostSizeXS.module.scss'
import { Category, Post } from '../../../types'
import { formatUrl, isLinkForVideo } from '../../../utils/Helpers'
import Kairon from '../Kairon/Kairon'

export const PostSizeXSProps = Record({
  post: Post,
  category: Union(Category, Null),
  subCategory: Union(Category, Null),
})

export type PostSizeXSProps = Static<typeof PostSizeXSProps>

const PostSizeXS = ({ post, category, subCategory }: PostSizeXSProps) => {
  PostSizeXSProps.check({
    post,
    category,
    subCategory,
  })

  const url = formatUrl(
    `/${encodeURIComponent(category?.name)}/${encodeURIComponent(
      subCategory?.name ?? '-'
    )}/${post.slug}`
  )

  if (!category) return null
  return (
    <Link href={url}>
      <a>
        <div className={`${styles.postSizeXS} relative cursor-pointer`}>
          {post.hasVideo ? (
            <img
              alt="yt-icon"
              src="/video-icon.svg"
              width={32}
              height={32}
              className="absolute top-2.5 right-2.5"
            />
          ) : null}
          {post.chiron_url ? (
            <div className="absolute">
              <Kairon url={post.chiron_url} name={post.chiron_name} />
            </div>
          ) : null}
          <div>
            {isLinkForVideo(post.url) ? (
              <video autoPlay muted loop src={post.url}>
                Your browser does not support videos.
              </video>
            ) : (
              <div
                className={`${styles.image} bg-cover md:bg-auto`}
                style={{ backgroundImage: `url('${post.url}')` }}
              />
            )}
          </div>
          <div
            className={`w-full py-2.5 px-0 sm:p-2.5 bg-white flex items-start ${styles.text}`}
          >
            <img src="/trougao.svg" className="h-3 pr-2 mt-1" />
            <h2
              className={`${styles.title} tracking-normal leading-6 overflow-ellipsis overflow-hidden`}
            >
              <span>
                {post.isNew ? (
                  <span className={`${styles.novo} pr-2`}>NOVO:</span>
                ) : null}
                {post.title}
              </span>
            </h2>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default PostSizeXS
