import Link from 'next/link'
import { Boolean, Null, Partial, Record, Static, Union } from 'runtypes'

import styles from './PostSizeL.module.scss'
import { Category, Post } from '../../../types'
import { formatUrl, isLinkForVideo } from '../../../utils/Helpers'
import Kairon from '../Kairon/Kairon'

export const PostSizeLProps = Record({
  post: Post,
  category: Union(Category, Null),
  subCategory: Union(Category, Null),
}).And(
  Partial({
    showTopMargin: Boolean,
  })
)
export type PostSizeLProps = Static<typeof PostSizeLProps>

const PostSizeL = ({
  post,
  showTopMargin = true,
  category,
  subCategory,
}: PostSizeLProps) => {
  PostSizeLProps.check({
    post,
    category,
    subCategory,
  })

  const url = formatUrl(
    `/${encodeURIComponent(category?.name)}/${encodeURIComponent(
      subCategory?.name ?? '-'
    )}/${post.slug}`
  )

  if (!category) return null
  return (
    <Link href={url}>
      <a>
        <div
          className={`${styles.postSizeL} ${
            showTopMargin ? 'mt-2.5' : ''
          }  cursor-pointer relative`}
        >
          {post.chiron_url ? (
            <Kairon url={post.chiron_url} name={post.chiron_name} />
          ) : null}
          <div className="absolute top-10 right-2.5">
            {post.hasVideo ? (
              <img alt="yt-icon" src="/video-icon.svg" width={32} height={32} />
            ) : null}
          </div>
          <div>
            {isLinkForVideo(post.url) ? (
              <video autoPlay muted loop src={post.url}>
                Your browser does not support videos.
              </video>
            ) : (
              <div
                className={`${styles.image} md:bg-auto`}
                style={{ backgroundImage: `url('${post.url}')` }}
              />
            )}
          </div>
          <div className={`w-full p-2.5 bg-white ${styles.text}`}>
            <div className="flex items-center">
              <img src="/trougao.svg" className="h-3 pr-2" />
              <div className="w-full flex items-start justify-between">
                <p className={`${styles.subtitle} tracking-normal leading-5`}>
                  {post.subtitle}
                </p>
              </div>
            </div>
            <h3 className={`${styles.title} tracking-normal`}>
              {post.isNew ? (
                <span className={`${styles.novo} pr-2`}>NOVO:</span>
              ) : null}
              <span>{post.title}</span>
            </h3>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default PostSizeL
