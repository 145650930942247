import { connect } from 'react-redux'

import styles from './FeedAdBanner.module.scss'
import { Banner, BannerPosition } from '../../../types/banner'
import { getFileFullPath, getRandomArrayItem } from '../../../utils/Helpers'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import config from '../../../config.json'
import axios from 'axios'
interface FeedAdBanner {
  banners: Banner[]
  position: BannerPosition
}

const FeedAdBanner = ({ banners, position }: FeedAdBanner) => {
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => setScreenWidth(window.innerWidth), [])

  const randomBanner = getRandomArrayItem(
    banners.filter((banner) => banner.position === position)
  )

  if (!randomBanner) return null

  const bannerImageUrl =
    randomBanner[`banner_image_mobile_position_${Number(position)}`] &&
    screenWidth < 900
      ? randomBanner[`banner_image_mobile_position_${Number(position)}`] ?? null
      : randomBanner.banner_image

  return (
    <div>
      <p className={`${styles.advertisement} h-5 flex items-center`}>
        ADVERTISEMENT
      </p>
      {/*eslint-disable-next-line*/}
      <a
        target="_blank"
        href={randomBanner.banner_url}
        onClick={() => {
          axios
            .post(`${config.apiBaseUrl}banner-clicked-api`, {
              id: randomBanner.id,
              position: position,
              // eslint-disable-next-line
              from_url: window.location.href,
              // eslint-disable-next-line
              to_url: randomBanner.banner_url,
            })
            .catch((e) => Sentry.captureException(e))
        }}
      >
        <div className={`${styles.fullWidthAdBanner} ml-auto mr-auto`}>
          <img className="w-full" src={getFileFullPath(bannerImageUrl)} />
        </div>
      </a>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    banners: state.banners,
  }
}

export default connect(mapStateToProps)(FeedAdBanner)
