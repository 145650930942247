/* eslint-disable @typescript-eslint/camelcase */
import { connect } from 'react-redux'
import { getCookies } from 'cookies-next'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as Sentry from '@sentry/nextjs'

import Content from '../components/Content/Content'
import FullWidthAdBanner from '../components/Advertisement/FullWidthAdBanner/FullWidthAdBanner'
import PostSizeL from '../components/Content/PostSizeL/PostSizeL'
import PostSizeS from '../components/Content/PostSizeS/PostSizeS'
import RightSidePanel from '../components/Content/RightSidePanel/RightSidePanel'
import NewsApi from '../api/news'
import BannerApi from '../api/banners'
import SearchResults from '../components/Content/SearchResults/SearchResults'
import { actions, initializeStore } from '../store'
import authenticationApi from '../api/authentication'
import { getFileFullPath, isPostNew } from '../utils/Helpers'
import PostSizeXS from '../components/Content/PostSizeXS/PostSizeXS'
import FeedAdBanner from '../components/Advertisement/FeedAdBanner/FeedAdBanner'
import { BannerPosition } from '../types/banner'
import SideAdBanner from '../components/Advertisement/SideAdBanner/SideAdBanner'
import SideVerticalAdvertisementBar from '../components/Advertisement/SideVerticalAdvertisementBar/SideVerticalAdvertisementBar'
import EmptySearchResults from '../components/Content/EmptySearchResults/EmptySearchResults'
import LiveStreams from '../components/Content/LiveStreams/LiveStreams'
import MobileNewsTabs from '../components/Content/MobileNewsTabs/MobileNewsTabs'
import SportScheduleFeed from '../components/Content/SportScheduleFeed/SportScheduleFeed'

export const renderAds = (i: number, width: number): JSX.Element => {
  if (width < 765) {
    return (
      <div className="float-left w-full block lg:hidden">
        {/*Math.floor(i / 9) % 3 === 0 ? (
          <div>
            <div className="mb-2.5" key={`banner-${BannerPosition.THREE}-${i}`}>
              <SideAdBanner position={BannerPosition.THREE} />
            </div>
            {/*width < 900 && width > 0 && <LiveNewsFeed />*/}
        {/*<div className="mb-2.5" key={`banner-${BannerPosition.FOUR}-${i}`}>
              <SideAdBanner position={BannerPosition.FOUR} />
            </div>
          </div>
        ) : null*/}
        {/*Math.floor(i / 9) % 3 === 1 ? (
          <div key={`banner-${new Date()}`}>
            <div className="mb-2.5">
              <FeedAdBanner position={BannerPosition.FIVE} />
            </div>
            {/*width < 900 && width > 0 && <MostReadFeed />*/}
        {/* <div className="mb-2.5">
              <FeedAdBanner position={BannerPosition.SIX} />
            </div>
          </div>
        ) : null */}

        {/*Math.floor(i / 9) % 3 === 2 ? (
          <div>
            <div className="mb-2.5" key={`banner-${BannerPosition.SEVEN}-${i}`}>
              <FeedAdBanner position={BannerPosition.SEVEN} />
            </div>
            {width < 900 && width > 0 && <SportScheduleFeed />}
            <div className="mb-2.5" key={`banner-${BannerPosition.EIGHT}-${i}`}>
              <FeedAdBanner position={BannerPosition.EIGHT} />
            </div>
          </div>
        ) : null*/}
      </div>
    )
  }
  return (
    <div>
      <div id="desktop" className="hidden float-left lg:block">
        {i === 10 && <FeedAdBanner position={BannerPosition.FIVE} />}
        {i === 12 && <FeedAdBanner position={BannerPosition.SIX} />}
        {i === 14 && <FeedAdBanner position={BannerPosition.SEVEN} />}
        {i === 17 && <FeedAdBanner position={BannerPosition.EIGHT} />}
      </div>
    </div>
  )
}

export const renderMobileAds = (i: number, width: number): JSX.Element => {
  if (width < 765) {
    return (
      <div className="float-left w-full block lg:hidden">
        {i % 16 === 3 && (
          <div className="mb-2.5">
            <SideAdBanner position={BannerPosition.THREE} />
          </div>
        )}

        {i % 16 === 4 && (
          <div className="mb-2.5">
            <SideAdBanner position={BannerPosition.FOUR} />
          </div>
        )}

        {i % 16 === 6 && (
          <div className="mb-2.5">
            <FeedAdBanner position={BannerPosition.FIVE} />
          </div>
        )}

        {i % 16 === 9 && (
          <div className="mb-2.5">
            <FeedAdBanner position={BannerPosition.SIX} />
          </div>
        )}

        {i % 16 === 10 && (
          <div>
            <div className="mb-2.5">
              <FeedAdBanner position={BannerPosition.SEVEN} />
            </div>
            {width < 900 && width > 0 && <SportScheduleFeed />}
          </div>
        )}

        {i % 16 === 12 && (
          <div className="mb-2.5">
            <FeedAdBanner position={BannerPosition.EIGHT} />
          </div>
        )}

        {i % 16 === 15 && (
          <div className="mb-2.5">
            <FeedAdBanner position={BannerPosition.NINE} />
          </div>
        )}

        {i % 16 === 0 && (
          <div className="mb-2.5">
            <FeedAdBanner position={BannerPosition.TEN} />
          </div>
        )}

        {i % 16 === 2 && (
          <div className="mb-2.5">
            <FeedAdBanner position={BannerPosition.ELEVEN} />
          </div>
        )}
      </div>
    )
  }
  return null
}

const renderTabsNews = (i: number, width: number): JSX.Element => {
  return i === 3 && width < 900 && width > 0 ? <MobileNewsTabs /> : null
}

const Naslovna = ({
  searchResultsPagination,
  mainPagePosts,
  mainPagePostsCurrentPage,
  setMainPagePosts,
  emptySearchResults,
}): JSX.Element => {
  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  const listenToScroll = () => {
    localStorage.setItem(
      'scrollTop',
      String(document.documentElement.scrollTop)
    )
  }

  const listenPageRefresh = () => {
    localStorage.removeItem('scrollTop')
    localStorage.removeItem('posts')
    localStorage.removeItem('pageNumber')
    return true
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', listenPageRefresh)
    return () => window.removeEventListener('beforeunload', listenPageRefresh)
  }, [])

  useEffect(() => {
    if (
      localStorage.getItem('page') === 'post' &&
      JSON.parse(localStorage.getItem('posts'))
    ) {
      setMainPagePosts(
        JSON.parse(localStorage.getItem('posts')),
        Number(localStorage.getItem('pageNumber')),
        true
      )
      setTimeout(() => {
        window.scroll({
          top: Number(localStorage.getItem('scrollTop')),
          behavior: 'smooth',
        })
      }, 200)
    }
  }, [])

  const onInfinityScrollBottomHit = async () => {
    const posts = await fetchPosts(mainPagePostsCurrentPage + 1)
    setMainPagePosts(posts, mainPagePostsCurrentPage + 1)
  }

  const numberOfPrimaryPosts = mainPagePosts.filter(
    (item) => item.post && item.post.primary === 1 && item.post.primary_position
  ).length

  const renderSeparator = (i: number, width: number) => {
    if (width < 765) {
      if (i === 3) {
        return (
          <div className="float-left mt-2.5 separator border-4">
            <img alt="section separator" src="/separator.gif" />
          </div>
        )
      }
    } else {
      if (numberOfPrimaryPosts && i + 1 === numberOfPrimaryPosts) {
        return (
          <div className="float-left mt-2.5 separator border-4">
            <img alt="section separator" src="/separator.gif" />
          </div>
        )
      }
    }
  }

  return (
    <div className="flex mx-auto page">
      <Content>
        <FullWidthAdBanner />
        {emptySearchResults && <EmptySearchResults />}
        {!emptySearchResults && (
          <div className="flex content-margin">
            <InfiniteScroll
              dataLength={mainPagePosts.length}
              next={onInfinityScrollBottomHit}
              hasMore={true}
              loader={null}
              className="overflow-hidden"
            >
              <div>
                {searchResultsPagination?.data &&
                searchResultsPagination.data.length ? (
                  <SearchResults />
                ) : (
                  mainPagePosts.map((item, i) => {
                    if (i % 7 === 0 || i % 7 === 3 || i % 7 === 5) {
                      return (
                        <div className="float-left w-full" key={i}>
                          <PostSizeL
                            key={i}
                            post={{
                              title: item.post.name,
                              subtitle: item.post.subtitle,
                              url: getFileFullPath(item.post.featured_image),
                              slug: item.post.slug,
                              chiron_url: item.post.chiron_url,
                              chiron_name: item.post.chiron_name,
                              isNew: isPostNew(
                                item.post.is_new,
                                item.post.published_at
                              ),
                              hasVideo:
                                item.post.content?.includes('<video') ||
                                item.post.content?.includes(
                                  '<iframe src="https://www.youtube.com'
                                ),
                            }}
                            showTopMargin={i !== 0}
                            category={item.category}
                            subCategory={item.subCategory}
                          />
                          {renderSeparator(i, screenWidth)}
                          {renderTabsNews(i, screenWidth)}
                          {screenWidth > 0 && screenWidth < 900 && (
                            <div className="mt-2.5 float-left w-full">
                              <LiveStreams />
                            </div>
                          )}
                          {i > 9 && i < 19 && renderAds(i, screenWidth)}
                          {i > 2 && renderMobileAds(i, screenWidth)}
                        </div>
                      )
                    } else if (i % 7 === 1) {
                      return (
                        <div
                          key={i}
                          className="float-left mt-2.5 w-full px-2.5 md:px-0 bg-white "
                        >
                          <div className="flex">
                            <div className="pr-1 w-1/2">
                              <PostSizeXS
                                post={{
                                  title: item.post.name,
                                  subtitle: item.post.subtitle,
                                  url: getFileFullPath(
                                    item.post.featured_image
                                  ),
                                  slug: item.post.slug,
                                  chiron_url: item.post.chiron_url,
                                  chiron_name: item.post.chiron_name,
                                  isNew: isPostNew(
                                    item.post.is_new,
                                    item.post.published_at
                                  ),
                                  hasVideo:
                                    item.post.content?.includes('<video') ||
                                    item.post.content?.includes(
                                      '<iframe src="https://www.youtube.com'
                                    ),
                                }}
                                category={item.category}
                                subCategory={item.subCategory}
                              />
                            </div>
                            <div className="pl-1 w-1/2">
                              {mainPagePosts[i + 1] !== undefined ? (
                                <PostSizeXS
                                  post={{
                                    title: mainPagePosts[i + 1]['post']['name'],
                                    subtitle:
                                      mainPagePosts[i + 1]['post']['subtitle'],
                                    url: getFileFullPath(
                                      mainPagePosts[i + 1]['post'][
                                        'featured_image'
                                      ]
                                    ),
                                    slug: mainPagePosts[i + 1]['post']['slug'],
                                    chiron_url:
                                      mainPagePosts[i + 1]['post'][
                                        'chiron_url'
                                      ],
                                    chiron_name:
                                      mainPagePosts[i + 1]['post'][
                                        'chiron_name'
                                      ],
                                    isNew: isPostNew(
                                      item.post.is_new,
                                      item.post.published_at
                                    ),
                                    hasVideo:
                                      mainPagePosts[i + 1]['post'][
                                        'content'
                                      ]?.includes('<video') ||
                                      mainPagePosts[i + 1]['post'][
                                        'content'
                                      ]?.includes(
                                        '<iframe src="https://www.youtube.com'
                                      ),
                                  }}
                                  category={mainPagePosts[i + 1]['category']}
                                  subCategory={
                                    mainPagePosts[i + 1]['subCategory']
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                          {renderSeparator(i, screenWidth)}
                          {renderSeparator(i + 1, screenWidth)}
                          {renderTabsNews(i, screenWidth)}
                          {i > 2 && renderMobileAds(i, screenWidth)}
                        </div>
                      )
                    } else if (i % 7 === 4 || i % 7 === 6) {
                      return (
                        <div key={i}>
                          <div className="float-left w-full" key={i}>
                            <PostSizeS
                              post={{
                                title: item.post.name,
                                subtitle: item.post.subtitle,
                                url: getFileFullPath(item.post.featured_image),
                                slug: item.post.slug,
                                chiron_url: item.post.chiron_url,
                                chiron_name: item.post.chiron_name,
                                isNew: isPostNew(
                                  item.post.is_new,
                                  item.post.published_at
                                ),
                                hasVideo:
                                  item.post.content?.includes('<video') ||
                                  item.post.content?.includes(
                                    '<iframe src="https://www.youtube.com'
                                  ),
                              }}
                              category={item.category}
                              subCategory={item.subCategory}
                              imagePosition={i % 7 === 4 ? 'right' : 'left'}
                            />
                            {renderSeparator(i, screenWidth)}
                            {renderTabsNews(i, screenWidth)}
                            {i > 2 && renderMobileAds(i, screenWidth)}
                          </div>
                        </div>
                      )
                    }
                  })
                )}
              </div>
            </InfiniteScroll>
            {screenWidth >= 1024 ? <RightSidePanel /> : null}
          </div>
        )}
      </Content>
      <SideVerticalAdvertisementBar />
    </div>
  )
}

const fetchPosts = async (page = 0) => {
  const newsResponse = await NewsApi.getNews(page)

  const formattedRegularPosts = newsResponse.data.regular_posts.data
    .filter((category) => category.posts.data.length)
    .sort((categoryA, categoryB) =>
      categoryB.sort_order > 0
        ? categoryA.sort_order - categoryB.sort_order
        : categoryB.sort_order - categoryA.sort_order
    )
    .map((category) => {
      return category.posts.data.map((post) => {
        return {
          post: post,
          category: {
            id: category.id,
            name: category.name,
            color: category.color,
          },
          subCategory: null,
        }
      })
    })
    .flat()

  const formattedPrimaryPosts = newsResponse.data.primary_posts.data
    .filter((post) => post.primary_position)
    .map((post) => {
      return {
        post: post,
        category: post.categories.length ? post.categories[0] : null,
        subCategory: post.categories.length
          ? post.categories.find((category) => category.parent_category) ?? null
          : null,
      }
    })
    .sort(function (a, b) {
      return a.post.primary_position - b.post.primary_position
    })

  return [...formattedPrimaryPosts, ...formattedRegularPosts]
}

export async function getServerSideProps(ctx) {
  ctx.res.setHeader(
    'Cache-Control',
    'public, s-maxage=150, stale-while-revalidate=180'
  )
  const reduxStore = initializeStore()

  const posts = await fetchPosts()
  let banners = []
  try {
    const bannersResponse = await BannerApi.getUncategorizedBanners()
    banners = bannersResponse.data
  } catch (e) {
    banners = []
    Sentry.captureException(e)
  }

  const authResponse = await authenticationApi.getUser(
    getCookies(ctx, 'access_token')
  )

  return {
    props: {
      initialReduxState: {
        ...reduxStore.getState(),
        banners,
        isUserLoggedIn: authResponse.data.id ? true : false,
        user: authResponse.data.id ? authResponse.data : null,
        mainPagePosts: posts,
      },
    },
  }
}

const mapStateToProps = (state) => ({
  searchResultsPagination: state.searchResultsPagination,
  mainPagePosts: state.mainPagePosts,
  mainPagePostsCurrentPage: state.mainPagePostsCurrentPage,
  emptySearchResults: state.emptySearchResults,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setMainPagePosts: (posts, page, hardReset = false) => {
      dispatch({
        type: actions.SET_MAIN_PAGE_POSTS,
        payload: {
          posts,
          page,
          hardReset,
        },
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Naslovna)
