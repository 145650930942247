import { connect } from 'react-redux'
import moment from 'moment'

import styles from './LiveStreams.module.scss'
import NewsFeedItem from '../NewsFeedItem/NewsFeedItem'
import { useEffect, useState } from 'react'
import LiveStreamsAPi from '../../../api/live-streams'
import { actions } from '../../../store'

interface LiveStream {
  id: number
  title: string
  sport: string
  league: string
  game: string
  link: string
  time: string
  sort_order: number
}

const LiveStreams = (): JSX.Element => {
  const [streams, setStreams] = useState<LiveStream[]>([])

  useEffect(() => {
    fetchLiveStreams()
  }, [])

  const fetchLiveStreams = async () => {
    const response = await LiveStreamsAPi.getStreams()
    setStreams(response.data?.data ?? null)
  }

  return (
    <>
      {streams && streams.length > 0 && (
        <div className={`${styles.liveStreamFeed} bg-white pb-2.5`}>
          <p className={styles.title}>
            <span className="px-10 leading-8 font-bold text-white">
              Uživo prenosi
            </span>
          </p>
          {streams
            .sort(function (a, b) {
              return a.sort_order - b.sort_order
            })
            .map((stream: LiveStream, index: number) => {
              return (
                <NewsFeedItem
                  key={index}
                  time={moment(stream.time).format('HH:mm')}
                  subtitle={`${stream.sport} / ${stream.league}`}
                  title={stream.game}
                  category={null}
                  slug={null}
                  isClickable={true}
                  isSportScheduleItem={true}
                  liveStreamUrl={stream.link}
                  isLiveStream={true}
                />
              )
            })}
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    posts: state.newestPosts,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNewestPosts: (data) => {
      dispatch({
        type: actions.SET_NEWEST_POSTS,
        payload: {
          newestPosts: data,
        },
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreams)
